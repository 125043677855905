import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
//
const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: "active" } : null;
};
//
export default ({ navigation }) => (
  <Wrap>
    <List>
      {navigation.map(({ id, path, title }) => (
        <Item key={id}>
          <Link to={path} title={title} getProps={isPartiallyActive}>
            {title}
          </Link>
        </Item>
      ))}
    </List>
  </Wrap>
);
//
const Wrap = styled.div``;
const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
`;
const Item = styled.li`
  margin: 0 0 0.5rem;
  padding: 0;

  @media (min-width: 480px) {
    display: inline-block;
    margin: 0 0.5rem;
  }

  a {
    transition: opacity 150ms ease-in-out;

    &:hover {
      opacity: 0.5;
    }

    &.active {
      border-bottom: 1px solid currentColor;
    }
  }
`;
