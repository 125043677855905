import React, { useEffect, useState } from "react";
import styled from "styled-components";
//
import Image from "./../image";
//
export default ({ dimensions, ...rest }) => {
  const [maxWidth, setMaxWidth] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined" && !maxWidth) {
      const { height, width } = dimensions;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth < 1568 ? window.innerWidth : 1568;

      if (height / width > windowHeight / windowWidth) {
        setMaxWidth(`${(width / height) * windowHeight * 0.9}px`);
      } else {
        setMaxWidth("none");
      }
    }
  });

  return (
    <Wrap maxWidth={maxWidth}>
      <Image dimensions={dimensions} {...rest} />
    </Wrap>
  );
};
//
const Wrap = styled.div`
  margin: 0 auto 1rem;
  max-width: ${({ maxWidth }) => maxWidth || "none"};
`;
