import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
//
import { RichText } from "components/prismic";
//
export default ({ caption, url }) => (
  <Wrap>
    <PlayerWrap>
      <ReactPlayer
        url={url}
        className="react-player"
        width="100%"
        height="100%"
      />
    </PlayerWrap>
    {caption && <RichText {...caption} />}
  </Wrap>
);
//
const Wrap = styled.div`
  margin: 0 auto 1rem;

  p {
    margin: 0.5rem 0 1rem;
  }
`;
const PlayerWrap = styled.div`
  margin: 0 auto;
  position: relative;
  padding-top: 56.25%;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
