import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
//
import Logo from "components/logo";
import Navigation from "containers/navigation";
//
export default () => (
  <Wrap>
    <LogoWrap>
      <Link to="/" title="Home" className="logo">
        <Logo />
      </Link>
    </LogoWrap>
    <Navigation />
  </Wrap>
);
//
const Wrap = styled.header`
  padding: 0 2rem 1rem;
`;

const LogoWrap = styled.figure`
  margin: 0 auto;
  max-width: 100%;
  padding: 1rem;
  width: 768px;

  .logo {
    display: block;
  }
`;
