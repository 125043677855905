import React from "react";
import styled from "styled-components";
//
import logo from "./imagez.png";
//
export default ({ ...rest }) => (
  <Wrap>
    <Image src={logo} ttile="Home" />
  </Wrap>
);
//
const Wrap = styled.figure``;
const Image = styled.img``;
