import React from "react";
import Loadable from "react-loadable";
import styled from "styled-components";
//
export default ({ url, title }) => {
  const Loading = ({ error }) => {
    if (error) {
      return <span>{title}</span>;
    }
    return null;
  };

  const Icon = Loadable({
    loader: () => import(`components/svg/${title}`),
    loading: Loading
  });

  return (
    <Wrap>
      <Item href={url} title={title}>
        <Icon />
      </Item>
    </Wrap>
  );
};
//
const Wrap = styled.li`
  display: inline-block;
  margin: 0;
  padding: 0.5rem 0;

  @media (min-width: 768px) {
    padding: 0 1rem;
  }
`;

const Item = styled.a`
  overflow: hidden;
  padding: 0 10px;
  text-align: center;
  transition: all 300ms;

  @media (min-width: 768px) {
    padding: 0 10px;
  }

  :hover {
    opacity: 0.6;
  }

  svg {
    height: 20px;
    display: inline-block;
    width: 32px;
  }
`;
