import React from "react";
import PropTypes from "prop-types";
import ProgressiveImage from "react-progressive-image";
import styled from "styled-components";
//
const webp = () => {
  if (typeof document === "undefined") return false;
  const elem = document.createElement("canvas");
  if (elem.getContext && elem.getContext("2d")) {
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  }
  return false;
};
//
const Image = ({
  alt,
  bounds,
  dimensions,
  dpi,
  mode,
  quality,
  url,
  ...rest
}) => {
  if (!url) {
    return null;
  }

  let q = quality;
  if (q < 0) {
    q = 75 - 10 * dpi;
  }

  const output = webp() ? "webp" : "jpg";

  const strippedUrl = url.replace(/^(https?:|)\/\//, "");
  const src = `//images.weserv.nl/?url=${strippedUrl}&w=${
    bounds.width
  }&q=${q}&dpr=${dpi}&output=${output}`;

  return (
    <ProgressiveImage src={src}>
      {(src, loading) => (
        <Img loading={loading} src={src} alt={alt} {...dimensions} {...rest} />
      )}
    </ProgressiveImage>
  );
};

Image.defaultProps = {
  dpi: 1,
  quality: -1
};

Image.propTypes = {
  dpi: PropTypes.number,
  quality: PropTypes.number,
  mode: PropTypes.string,
  url: PropTypes.string.isRequired,
  dimensions: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  }).isRequired
};

export default Image;
//
const Img = styled.img`
  bottom: 0;
  height: auto;
  left: 0;
  object-fit: contain;
  opacity: ${props => (props.loading ? 0 : 1)};
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 420ms ease-in;
  width: 100%;
`;
