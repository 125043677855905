import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
//
import Social from "./social";
//
export default ({ socialLinks, text }) => (
  <Wrap>
    <RichText {...text} />
    <List>
      {socialLinks.map(link => (
        <Social key={link.title} {...link} />
      ))}
    </List>
  </Wrap>
);
//
const Wrap = styled.footer`
  background-color: RGBA(241, 241, 241, 1);
  padding: 4rem;
  text-align: center;
`;
const List = styled.ul`
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0 auto;
  padding: 0;
`;
