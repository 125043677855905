import React from "react";
import { Root } from "react-static";
import { Location } from "@reach/router";
import styled from "styled-components";
//
import Meta from "containers/meta";
//
import Header from "components/header";
import Footer from "containers/footer";
//
export default ({ children }) => (
  <Root>
    <Meta />
    <Container>
      <Header />
      {children}
    </Container>
    <Location>
      {({ location }) => {
        if (location.pathname !== "/") return <Footer />;
      }}
    </Location>
  </Root>
);
//
const Container = styled.div`
  min-height: calc(100vh - 202px);
`;
