import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
//
import Image from "./../image";
//
export default ({ caption, images }) => (
  <Wrap>
    <Inner images={images.length}>
      {images.map((image, i) => (
        <Image key={i} {...image} />
      ))}
    </Inner>
    {caption && <RichText {...caption} />}
  </Wrap>
);
//
const Wrap = styled.div`
  p {
    margin: 0.5rem 0 1rem;
  }
`;
const Inner = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: ${({ images }) => `repeat(${images}, 1fr)`};
  }
`;
